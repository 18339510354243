import React from "react";
import { Link } from 'react-router-dom';
import UserConst from '../users-const';



const UserQuestionAnsweredField = ({ userQuestion }) => {


    const question = userQuestion?.relAnswer;

    const id = question?.id,
        name = question?.name,
        nickname = question?.nickname;

    return (
        <>
            { id ? <Link to={ `${ UserConst.basePath }/${ id }` }> { `${ name } (${ nickname })` }</Link> : <strong>&mdash;</strong> }
        </>
    )
}


export default UserQuestionAnsweredField;