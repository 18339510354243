import React, { useState, useEffect } from "react";
import { Input, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import UserForms from "../../forms";
import Icons from "components/icons";
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;

const UserTableFilter = ({
    setFilters = () => {},
    setFiltersExtra = () => {},
    setResetSorting = () => {},
    modelFilters = "usersFilters",
    modelSorting = "usersSorting",
    usersFilters,
    usersFiltersExtra,
    filters,
    filtersExtra,
    setSearchText,
    resetFields = [
        "SELLER_NUMBER",
        "DEALER_ID",
        "STATUS",
        "DIAMOND_STATUS",
        "ROLE",
        "LAST_VISIT_AT",
        "BRAND",
    ],
    formFieldsHide = [],
    objOrderBy,
}) => {
    const [form] = Form.useForm();

    const [filterList, setFilterList] = useState(null);
    const [filterListExtra, setFilterListExtra] = useState(null);
    const [count, setCount] = useState();

    let filterCount = helperFunc.countFilterList(count);

    /*eslint-disable */
    useEffect(() => {
        if (filters) {
            localStorage.setItem(
                "usersFilters",
                JSON.stringify({ ...usersFilters, ...filterList })
            );
        } else form.resetFields(resetFields);

        if (filtersExtra) {
            localStorage.setItem(
                "usersFiltersExtra",
                JSON.stringify({ ...usersFiltersExtra, ...filterListExtra })
            );
        } else form.resetFields(["SENT_PASSWORD"]);
    }, [filters, filtersExtra]);

    useEffect(() => {
        if (usersFilters) {
            setFilters(gqlBuilderWhere(usersFilters));
        }

        if (usersFiltersExtra) {
            setFiltersExtra(gqlBuilderWhere(usersFiltersExtra));
        }

        setCount({ ...usersFilters, ...usersFiltersExtra });
    }, []);
    /*eslint-enable */

    return (
        <>
            <Search
                className="filter-search"
                prefix={<SearchOutlined />}
                placeholder={
                    Localize({
                        children: "SEARCH.Input_Placeholder_Table_User",
                    }).props.children
                }
                onChange={(e) => setSearchText(e.target.value)}
            />

            {setFilters && (
                <Form
                    key="filters-form"
                    className="clear-css-prop table-filter-form"
                    layout="vertical"
                    form={form}
                >
                    <DrawerStandart
                        width="287"
                        extraClass={"modal-form filters"}
                        modalButton={
                            <Button>
                                <Icons.Filter />
                                <span>
                                    <Localize>
                                        FORMS.Button_Text_ApplyFilters
                                    </Localize>
                                </span>
                                {filterCount && (
                                    <span className="filter-count">
                                        {filterCount}
                                    </span>
                                )}
                            </Button>
                        }
                    >
                        <strong className="modal-title">
                            <Localize>FORMS.Title_Text_Filters</Localize>
                        </strong>

                        <UserForms.Filter
                            setCount={setCount}
                            setFilters={setFilters}
                            setFiltersExtra={setFiltersExtra}
                            setFilterList={setFilterList}
                            setFilterListExtra={setFilterListExtra}
                            filterList={filterList}
                            filterListExtra={filterListExtra}
                            gqlBuilderWhere={gqlBuilderWhere}
                            usersFilters={usersFilters}
                            usersFiltersExtra={usersFiltersExtra}
                            modelFilters={modelFilters}
                            formFieldsHide={formFieldsHide}
                            form={form}
                        />
                    </DrawerStandart>

                    <Button
                        type="link"
                        onClick={() => {
                            setCount({});
                            setResetSorting(objOrderBy);
                            setFilters(gqlBuilderWhere({}));
                            setFiltersExtra(gqlBuilderWhere({}));
                            setFilterList({});
                            setFilterListExtra({});
                            localStorage.removeItem("usersFiltersExtra");
                            localStorage.removeItem(modelFilters);
                            localStorage.removeItem(modelSorting);
                        }}
                    >
                        <span>
                            <Localize>FORMS.Button_Text_ResetFilters</Localize>
                        </span>
                    </Button>
                </Form>
            )}
        </>
    );
};

export default UserTableFilter;
