import React from 'react';
import { Form, Select } from "antd";
import Icons from "components/icons";
import { Localize } from "components/service";


const UserSentPasswordSelect = ({
    disabled = false,
    rules = false,
    initialValue,
    mode = '',
    form,
    name = 'sentPassword',
    onChangeSetFilter = () => { },
    label = Localize({ children: "FORMS.Input_Label_SentPassword" }).props.children
}) => {


    return (
        <Form.Item
            name={ name }
            label={ label }
            initialValue={ initialValue }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ label }` } ] }
        >
            <Select
                showSearch
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children } ${ label }` }
                popupClassName="select-serch-dropdown"
                optionFilterProp="value"
                onChange={
                    (value) => {
                        form.setFieldsValue({ [ name ]: value });
                        onChangeSetFilter(false, name, value);
                    } }
            >
                <Select.Option key="all" value="all"><Localize>FORMS.Select_OptionLabel_All</Localize> </Select.Option>
                <Select.Option key="sent" value="sent"><Localize>FORMS.Select_OptionLabel_Sent</Localize> </Select.Option>
                <Select.Option key="no_sent" value="no_sent"><Localize>FORMS.Select_OptionLabel_NoSent</Localize> </Select.Option>
            </Select>
        </Form.Item>
    );

}

export default UserSentPasswordSelect;


