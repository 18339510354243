import { gql } from '@apollo/client';


export const POST_DELETE = gql`
    mutation PostDelete($id: ID!){
        postDelete(id: $id){
            label
            message
        }
    }
`;


export const POST_COMMENT_DELETE = gql`
    mutation CommentDelete ($id: ID!) {
        commentDelete(id: $id) {
            id
            author {
                id
            }
        }
    }
`;


export const POST_ATTACHED_DELETE = gql`
    mutation AttachedDelete ($id: ID!) {
        attachedDelete(id: $id) {
            label
            message
        }
    }
`;


export const POSTS_EXPORT_EXCEL = gql`
    mutation PostsExportExel($dateFrom: String, $dateTo: String) {
        postsExportExel(dateFrom: $dateFrom, dateTo: $dateTo) {
            label
            message
            file
        }
    }
`;