import { useMutation } from "@apollo/client";
import { POSTS_EXPORT_EXCEL } from "graphql/mutation/post-gql";
import {
  successNotification,
  errorNotification,
} from "components/request-result";
import { saveAs } from "file-saver";

const useMutationPostExport = () => {
  function getFileNameFromUrl(url) {
    const value = url.split("/");
    return value[value.length - 1];
  }

  const [_setMutationPostsExport, { loading }] = useMutation(
    POSTS_EXPORT_EXCEL,
    {
      update(cache, { data }) {
        const {
          postsExportExel: { label, message, file },
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });

        saveAs(file, getFileNameFromUrl(file));
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationPostsExport,
    loadingMutationPostsExport: loading,
  };
};

export default useMutationPostExport;
