import React from "react";
import { Form, Select } from "antd";
import Icons from "components/icons";
import { Localize } from "components/service";

const UserBrandSelect = ({
    disabled = false,
    rules = false,
    form,
    initialValue,
    mode = "",
    name = "brand",
    onChangeSetFilter = () => {},
    label = Localize({ children: "FORMS.Input_Label_CompanyName" }).props.children,
}) => {
    return (
        <Form.Item
            name={name}
            label={label}
            initialValue={initialValue}
            rules={
                rules && [
                    {
                        required: true,
                        message: `${
                            Localize({ children: "FORM_RULES.Required_Choose" })
                                .props.children
                        } ${label}`,
                    },
                ]
            }
        >
            <Select
                showSearch
                suffixIcon={<Icons.Arrow />}
                mode={mode}
                className="select-serch-input"
                disabled={disabled}
                placeholder={`${
                    Localize({ children: "FORMS.Input_Placeholder_Choose" })
                        .props.children
                } ${label}`}
                popupClassName="select-serch-dropdown"
                optionFilterProp="value"
                onChange={(value) => {
                    form.setFieldsValue({ [name]: value });
                    onChangeSetFilter(false, name, value);
                }}
            >
                <Select.Option key="notSelect" value={null}>
                    <Localize>FORMS.Input_Placeholder_NotAssigned</Localize>
                </Select.Option>

                <Select.Option key="ora" value="ORA">
                    <Localize>ORA</Localize>
                </Select.Option>
                <Select.Option key="wey" value="WEY">
                    <Localize>WEY</Localize>
                </Select.Option>
            </Select>
        </Form.Item>
    );
};

export default UserBrandSelect;
