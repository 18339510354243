import React from "react";
import { Form, Button, DatePicker } from "antd";
import { postUseMutation } from "../../hooks";
import { Localize } from "components/service";
import { timeFormat } from "utils/helper-functions";

const { RangePicker } = DatePicker;

const PostsExportForm = ({ action }) => {
    const [ form ] = Form.useForm();

    const { _setMutationPostsExport, loadingMutationPostsExport } =
        postUseMutation.exportPosts();

    return (
        <Form
            key="edit-user-question-form"
            layout="vertical"
            form={form}
            className="model-form edit-user-question-form"
            onFinish={(values) => {
                _setMutationPostsExport({
                    variables: {
                        dateFrom: timeFormat(values?.period[0], "YYYY-MM-DD"),
                        dateTo: timeFormat(values?.period[1], "YYYY-MM-DD"),
                    },
                });
                action();
            }}
        >
            <Form.Item
                name="period"
                label={<Localize>FORMS.Input_Label_Period</Localize>}
            >
                <RangePicker
                    separator="&ndash;"
                    placeholder={[
                        Localize({
                            children: "FORMS.Input_Placeholder_StartDate",
                        }).props.children,
                        Localize({
                            children: "FORMS.Input_Placeholder_EndDate",
                        }).props.children,
                    ]}
                />
            </Form.Item>

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingMutationPostsExport}
                >
                    <Localize>FORMS.Button_Text_Apply</Localize>
                </Button>
            </div>
        </Form>
    );
};

export default PostsExportForm;
