import useMutationPostDelete from "./post-use-mutation-delete";
import useMutationPostExport from "./post-use-mutation-export";
import useMutationPostCommentDelete from "./post-comment-use-mutation-delete";
import useMutationPostAttachedDelete from "./post-attached-use-mutation-delete";


export const postUseMutation = {
  delete: useMutationPostDelete,
  commentDelete: useMutationPostCommentDelete,
  attachedDelete: useMutationPostAttachedDelete,
  exportPosts: useMutationPostExport
}