import UserAddСheckboxSelect from "./user-add-сheckbox-select";
import UserLanguageSelect from "./user-language-select";
import UserGenderSelect from "./user-gender-select";
import UserSalutationSelect from "./user-salutation-select";
import UserRoleSelect from "./user-role-select";
import UserStatusSelect from "./user-status-select";
import UserAddRadioSelect from "./user-add-radio-select";
import UserJobTypeSelect from "./user-job-type-select";
import UserVisitedSelect from "./user-visited-select";
import UserDiamondStatusSelect from "./user-diamond-status-select";
import UserBrandSelect from "./user-brand-select";
import UserSentPasswordSelect from "./user-sent-password-select";



const UserFormItems = {
    AddUserСheckboxSelect: UserAddСheckboxSelect,
    AddUserRadioSelect: UserAddRadioSelect,
    LanguageSelect: UserLanguageSelect,
    GenderSelect: UserGenderSelect,
    SalutationSelect: UserSalutationSelect,
    RoleSelect: UserRoleSelect,
    VisitedSelect: UserVisitedSelect,
    StatusSelect: UserStatusSelect,
    JobTypeSelect: UserJobTypeSelect,
    DiamondStatusSelect: UserDiamondStatusSelect,
    BrandSelect: UserBrandSelect,
    SentPasswordSelect: UserSentPasswordSelect,
};

export default UserFormItems;