import React from "react";
import { useQuery } from "@apollo/client";
import { GET_USER } from "graphql/query/user-gql";
import { Avatar } from "antd";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import UserRoute from "components/routes/user/user-route";
import { Localize } from "components/service";

const UserPage = ({ match, history }) => {
    useActiveMenuItem(["users"], ["users"]);

    const path = history.location.pathname.split("/").pop();
    const userID = match ? parseInt(match.params.id) : null;

    const { data: { user } = {}, loading } = useQuery(GET_USER, {
        skip: !userID,
        variables: {
            id: userID,
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    });

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props
                .children,
            path: "overview",
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Dealer" }).props
                .children,
            path: "dealer",
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Posts" }).props
                .children,
            path: "posts",
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_UserQuestions" })
                .props.children,
            path: "questions",
        },
    ];

    const avatar = userID ? (
        <Avatar
            shape="circle"
            size="large"
            icon={
                !!user?.avatar ? (
                    <img src={`${user.avatar}`} alt="avatar" />
                ) : (
                    user?.initials
                )
            }
        />
    ) : (
        ""
    );

    return (
        <PageWrapDefault
            className="page-user"
            loading={loading}
            title={
                path === "create" ? (
                    <Localize>PAGES.Title_CreateUser</Localize>
                ) : !loading && user ? (
                    user.name
                ) : (
                    "Page 404"
                )
            }
            dataExist={path === "create" ? true : !loading && user}
            pageNavbar={
                !loading && user
                    ? pageNavbar.filter(({ path }) =>
                          !user?.dealer ? path !== "dealer" : path !== ""
                      )
                    : false
            }
            avatar={avatar}
            userBrand={{
                brand: user?.brandArray?.filter((item) => item && item !== "0"),
            }}
            staticPath={`/users/${userID}`}
        >
            <UserRoute user={user} history={history} loading={loading} />
        </PageWrapDefault>
    );
};

export default UserPage;
