import UserFields from "../fields";
import { Localize } from "components/service";
import UserHelpers from "../helpers";

let usersColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: "id",
        columnIndex: "ID",
        sorter: true,
        align: "center",
        width: "5%",
    },
    {
        title: <Localize>TABLES.Column_Title_FullName</Localize>,
        dataIndex: "name",
        columnIndex: "NAME",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Username</Localize>,
        dataIndex: "nickname",
        columnIndex: "NICKNAME",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Contacts</Localize>,
        dataIndex: "contacts",
        columnIndex: "CONTACTS",
    },
    {
        title: <Localize>TABLES.Column_Title_SellerNumber</Localize>,
        dataIndex: "seller_number",
        columnIndex: "SELLER_NUMBER",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Dealer</Localize>,
        dataIndex: "dealer",
        columnIndex: "DEALER",
    },
    {
        title: <Localize>TABLES.Column_Title_CompanyName</Localize>,
        dataIndex: "brandArray",
        columnIndex: "BRAND",
        sorter: true,
        align: "center",
    },
    {
        title: <Localize>TABLES.Column_Title_Role</Localize>,
        dataIndex: "role",
        columnIndex: "ROLE",
        align: "center",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Posts</Localize>,
        dataIndex: "public_posts",
        columnIndex: "PUBLIC_POSTS",
        align: "center",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Followers</Localize>,
        dataIndex: "followers",
        columnIndex: "FOLLOWERS",
        align: "center",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_GoldStatus</Localize>,
        dataIndex: "diamond_status",
        columnIndex: "DIAMOND_STATUS",
        align: "center",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Status</Localize>,
        dataIndex: "status",
        columnIndex: "STATUS",
        align: "center",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_LastVisit</Localize>,
        dataIndex: "last_visit_at",
        columnIndex: "LAST_VISIT_AT",
        align: "center",
        sorter: true,
    },
    {
        // title: 'action',
        dataIndex: "action",
        align: "center",
        width: "4%",
    },
];

const usersData = ({
    model,
    actionFieldsHide,
    entityRemoveUser,
    variables,
}) => {
    if (!model) {
        return [];
    }

    return model.map((user) => {
        return {
            key: user.id,
            id: user.id,
            name: <UserFields.Info user={user} />,
            nickname: user.nickname ?? <strong>&mdash;</strong>,
            contacts: <UserFields.Contact user={user} />,
            seller_number: user.seller_number ?? <strong>&mdash;</strong>,
            dealer: <UserFields.Dealer user={user} />,
            brandArray: user?.brandArray
                ?.filter((item) => item && item !== "0")
                .join(", ") || <strong>&mdash;</strong>,
            role: user.role,
            public_posts: user?.user_counters?.public_posts,
            followers: user?.user_counters?.followers,
            diamond_status: <UserFields.Radio user={user} />,
            status: user.status ? (
                <strong
                    className={`${UserHelpers.Functions.statusColor(
                        user.status
                    )}`}
                >
                    {user.status}
                </strong>
            ) : (
                <strong>&mdash;</strong>
            ),
            last_visit_at: user.last_visit_at ?? <strong>&mdash;</strong>,
            action: (
                <UserFields.Action
                    user={user}
                    variables={variables}
                    actionFieldsHide={actionFieldsHide}
                    entityRemoveUser={entityRemoveUser}
                />
            ),
        };
    });
};

const UsersTableHelper = (columnsHide = []) => {
    usersColumns = usersColumns.filter(
        ({ dataIndex }) => !columnsHide?.includes(dataIndex)
    );

    return {
        columns: usersColumns,
        data: usersData,
    };
};

// call UsersPage to see all table fields const tableHelper = User.Helpers.TableMain()
// call UsersPage to hide 'status', 'role', 'dealer' table fields const tableHelper = User.Helpers.TableMain([ 'status', 'role', 'dealer' ])

export default UsersTableHelper;
