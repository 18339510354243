import React from "react";
import { Form, Input, Button, InputNumber, Switch, DatePicker } from "antd";
import { GET_DEALERS_CURSOR } from "graphql/query/dealer-gql";
// import { GET_COMPANIES_CURSOR } from "graphql/query/company-gql";
import { userUseMutation } from "../../hooks";
import UserFormItems from "../fields";
// import Dealer from "components/dealer";
import { FormElements } from "components/layout";
import { useMe } from "components/use-hooks";
import { Localize, EntityRemoveButton } from "components/service";
import { timeFormat } from "utils/helper-functions";
import moment from "moment";

const UserEditForm = ({ user }) => {
    const { me } = useMe();

    const [form] = Form.useForm();

    const { _setUserCreateUpdate, loadingMutationUserCreateUpdate: loading } =
        userUseMutation.createUpdate(user?.id);

    const { _setMutationUserDelete, loadingMutationUserDelete } =
        userUseMutation.delete(user?.id);

    return (
        <Form
            key="edit-user-form"
            layout="vertical"
            form={form}
            className="model-form edit-user-form"
            onFinish={(values) => {
                _setUserCreateUpdate({
                    variables: {
                        input: {
                            id: user?.id ? +user.id : undefined,
                            ...values,
                            brand: values.brand?.length === 0 || values.brand?.includes(null) ? null : values.brand,
                            status: values.status ? "active" : "block",
                            dealer_id: values.dealer_id || null,
                            seller_number: values.seller_number ?? undefined,
                            job_type: values.job_type ?? undefined,
                            phone: values.phone ?? undefined,
                            date_birth_at: values.date_birth_at
                                ? timeFormat(
                                      values?.date_birth_at,
                                      "YYYY-MM-DD"
                                  )
                                : undefined,
                            date_entrance_at: values.date_entrance_at
                                ? timeFormat(
                                      values?.date_entrance_at,
                                      "YYYY-MM-DD"
                                  )
                                : undefined,
                            about: values.about ?? undefined,
                        },
                    },
                });
            }}
        >
            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
                <UserFormItems.SalutationSelect
                    initialValue={user?.salutation}
                />

                <Form.Item
                    name="name"
                    label={<Localize>FORMS.Input_Label_Name</Localize>}
                    initialValue={user?.name}
                    rules={[
                        {
                            required: true,
                            message: (
                                <Localize>FORM_RULES.Required_Name</Localize>
                            ),
                        },
                    ]}
                >
                    <Input name="name" />
                </Form.Item>

                <Form.Item
                    name="date_birth_at"
                    label={<Localize>FORMS.Input_Label_DateBirth</Localize>}
                    initialValue={
                        user?.date_birth_at && moment(user?.date_birth_at)
                    }
                >
                    <DatePicker
                        placeholder={
                            Localize({
                                children: "FORMS.Input_Placeholder_SelectDate",
                            }).props.children
                        }
                        showTime
                        format="DD.MM.YYYY"
                    />
                </Form.Item>

                <Form.Item
                    name="date_entrance_at"
                    label={<Localize>FORMS.Input_Label_DateEntrance</Localize>}
                    initialValue={
                        user?.date_entrance_at && moment(user?.date_entrance_at)
                    }
                >
                    <DatePicker
                        placeholder={
                            Localize({
                                children: "FORMS.Input_Placeholder_SelectDate",
                            }).props.children
                        }
                        showTime
                        format="DD.MM.YYYY"
                    />
                </Form.Item>
            </div>

            <UserFormItems.JobTypeSelect initialValue={user?.job_type} />

            <div className="d-flex align-items-center">
                <Form.Item
                    name="status"
                    label={<Localize>FORMS.Input_Label_Status</Localize>}
                    initialValue={
                        user?.status === "inactive" || user?.status === "block"
                            ? false
                            : true
                    }
                    valuePropName={
                        user?.status === "inactive" || user?.status === "block"
                            ? false
                            : true
                    }
                    className="form-item-switch"
                >
                    <Switch
                        disabled={me?.id === user?.id}
                        defaultChecked={
                            user?.status === "inactive" ||
                            user?.status === "block"
                                ? false
                                : true
                        }
                    />
                </Form.Item>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                {user?.id && (
                    <Form.Item
                        name="diamond_status"
                        label={
                            <Localize>FORMS.Input_Label_GoldStatus</Localize>
                        }
                        initialValue={user?.diamond_status}
                        valuePropName={user?.diamond_status}
                        className="form-item-switch"
                    >
                        <Switch defaultChecked={user?.diamond_status} />
                    </Form.Item>
                )}
            </div>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
                <UserFormItems.GenderSelect initialValue={user?.gender} />

                <Form.Item
                    name="nickname"
                    label={<Localize>FORMS.Input_Label_UserName</Localize>}
                    initialValue={user?.nickname}
                    rules={[
                        {
                            required: true,
                            message: (
                                <Localize>
                                    FORM_RULES.Required_UserName
                                </Localize>
                            ),
                        },
                    ]}
                >
                    <Input name="nickname" />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label={<Localize>FORMS.Input_Label_Phone</Localize>}
                    initialValue={user?.phone}
                >
                    <Input name="phone" />
                </Form.Item>

                <Form.Item
                    name="email"
                    label={<Localize>FORMS.Input_Label_Email</Localize>}
                    initialValue={user?.email}
                    rules={[
                        {
                            type: "email",
                            required: true,
                            message: (
                                <Localize>FORM_RULES.Required_Email</Localize>
                            ),
                        },
                    ]}
                >
                    <Input name="email" />
                </Form.Item>

                <UserFormItems.LanguageSelect initialValue={user?.locale} />

                <UserFormItems.BrandSelect
                    form={form}
                    initialValue={ user?.brandArray }
                    mode="multiple"
                />
            </div>

            {/* <FormElements.CheckboxSelect
                form={form}
                query={GET_COMPANIES_CURSOR}
                model="companiesCursor"
                name="company_id"
                label="FORMS.Input_Label_AssignedCompanies"
                modalTitle="FORMS.Title_AddCompanies"
                modalButtonText="MODAL.Button_Text_AddCompanies"
                initialValue={
                    user?.relCompanies?.length
                        ? user.relCompanies.map(({ id, title }) => ({
                              value: id,
                              label: title,
                          }))
                        : false
                }
            /> */}

            <FormElements.RadioSelect
                form={form}
                query={GET_DEALERS_CURSOR}
                model="dealersCursor"
                name="dealer_id"
                label="FORMS.Input_Label_Dealer"
                modalTitle="FORMS.Title_ChooseDealer"
                modelCursorLabel={["company_name", "dealer_number"]}
                styleLabel={{ fontWeight: 700 }}
                initialValue={
                    user?.dealer_id
                        ? [
                              {
                                  value: user?.dealer_id,
                                  label: `${user?.dealer?.company_name} ${user?.dealer?.dealer_number}`,
                              },
                          ]
                        : false
                }
            />

            <Form.Item
                name="seller_number"
                label={<Localize>FORMS.Input_Label_SellerNumber</Localize>}
                initialValue={user?.seller_number}
            >
                <InputNumber
                    name="seller_number"
                    min={3}
                    max={1000000}
                    controls={false}
                    style={{ width: "100%" }}
                />
            </Form.Item>

            <UserFormItems.RoleSelect form={form} initialValue={user?.role} />

            <Form.Item
                name="about"
                label={<Localize>FORMS.Input_Label_Description</Localize>}
                initialValue={user?.about}
            >
                <Input.TextArea
                    maxLength={300}
                    placeholder={
                        Localize({
                            children:
                                "FORMS.Input_Placeholder_ShortDescription",
                        }).props.children
                    }
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    // showCount={
                    //     { formatter: ({ count, maxLength }) => maxLength - count }
                    // }
                />
            </Form.Item>

            <div className="form-btn-holder">
                {user?.id && (
                    <EntityRemoveButton
                        modalButton={
                            <Button>
                                <Localize>USER.Button_Text_Delete</Localize>{" "}
                            </Button>
                        }
                        nameEntity={
                            Localize({ children: "ENTITY.Modal_Title_User" })
                                .props.children
                        }
                        dataNameEntity={user?.name}
                        loading={loadingMutationUserDelete}
                        deleteMutation={_setMutationUserDelete}
                        variables={{
                            id: user?.id,
                        }}
                    />
                )}

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                >
                    {user?.id ? (
                        <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
                    ) : (
                        <Localize>USER.Button_Text_Create</Localize>
                    )}
                </Button>
            </div>
        </Form>
    );
};

export default UserEditForm;
