const filterArrToWhere = (filterArr, condition) => {
    const [whereItem, ...newArr] = filterArr;

    if (newArr.length) {
        return {
            ...whereItem,
            [condition]: [{ ...filterArrToWhere(newArr, condition) }],
        };
    }

    return whereItem;
};

export const gqlWhere = (filters, operator = "IN", condition = "AND") => {
    let filterArr = [];

    for (let [key, value] of Object.entries(filters)) {
        if (value) {
            filterArr.push({
                column: key.toUpperCase(),
                operator,
                value,
            });
        }
    }

    return filterArrToWhere(filterArr.reverse(), condition);
};

export const gqlBuilderWhere = (filters, condition = "AND") => {
    let filterArr = [];

    if (filters) {
        for (let [, item] of Object.entries(filters)) {
            if (Object.keys(item).length) {
                filterArr.push({ ...item });
            }
        }
    }

    return filterArrToWhere(filterArr.reverse(), condition);
};

export function removeField(obj, fieldName) {
    if (!obj) return null;

    if (obj.column === fieldName) {
        if (obj.AND && Array.isArray(obj.AND)) {
            const filteredAND = obj.AND.map((subObj) =>
                removeField(subObj, fieldName)
            ).filter(Boolean);

            return filteredAND.length === 1
                ? filteredAND[0]
                : { AND: filteredAND };
        } else {
            return null;
        }
    }

    if (obj.AND && Array.isArray(obj.AND)) {
        obj.AND = obj.AND.map((subObj) =>
            removeField(subObj, fieldName)
        ).filter(Boolean);

        if (obj.AND.length === 0) {
            delete obj.AND;
        }
    }

    return obj;
}
